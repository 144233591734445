@font-face {
  font-family: "Brandon";
  src: local("Brandon"), url("./fonts/brandon.otf") format("truetype");
}
body {
  /* overscroll-behavior: contain; */
  overflow-y: hidden;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* height: 100%;
  height: fill-available;
  height: -moz-available;
  height: -webkit-fill-available; */
  

  user-select: none;
  /* background-color: brown; */
  margin: 0;
  /* font-family: "Quattrocento", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pin {
  position: absolute;
  top: 0%;
  left: 0%;
  margin-left: 0px;
  border-color: var(--marker-color-var);
  background-color: var(--marker-color-var);
  border-radius: 50%;
  border-width: 8px;
  border-style: solid;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
}

.pin::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent;
  border-top-width: 17px;
  border-top-style: solid;
  border-top-color: var(--marker-color-var);
}
