h2 {
  color: white;
  text-align: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  border-radius: 2px;
  // height: 1vh;
  width: 100%;

  // backdrop-filter: blur(6px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.content {
  flex: 1;
  overflow-x: scroll;
  overflow-y: visible;
  display: flex;
  height:100%;
  align-items: center;
  justify-content: space-around;
  opacity: 1
}
::-webkit-scrollbar {
  display: none;
}
