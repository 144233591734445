.content {
  position: relative;
  width: 320px;
  height: 240px;
}

.content > div {
  position: absolute;
  width: 100%;
  height: 90px;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: white;
  line-height: 90px;
  padding-left: 32px;
  font-size: 14.5px;
  background: lightblue;
  text-transform: uppercase;
  letter-spacing: 2px;
  touch-action: none;
}
