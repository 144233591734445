.container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 12;
  height: 0vh;
  width: 100vw;
}

.barContainer {
  // display: flex;
  position: absolute;
  background: white;
  bottom: 0;
  left: 0;
  right:0;
  height: 5vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;
  // justify-content: space-between;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.titleContainer {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.levelsContainer {
  display: flex;
  padding: 16px;
}
