.buttonsContainer {
    z-index:11;
    position:fixed;
    top:72px;
    left:20px;
    display:flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    max-height:90%;
    background: #fffffff8;
    // backdrop-filter: blur(5px);
    border-radius: 5px;
    & ul  {
        margin : 5%
    }
    & li {
        margin-top : 5%
    }
    padding-left:16px;
    width: 20vw;
    min-width: 400px;
    padding-right:12px
}

.buttonText {
    color: #FFF;
    font-size: 1.5em;
}

.layersButtons {
    position : absolute;
    height:100%;
    right:0;
    top: 172px;
    z-index: 2;
}
.divider {
        clear:both;
        display:block;
        width: 96%;               
        background-color:#028090;
        height: 1px;
}
::-webkit-scrollbar {
    display: none;
  }